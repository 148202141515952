import { Injectable } from '@angular/core';
import { AppStoreService } from '../store/app-store.service';
import { Token } from '../models/token.model';
import { PermissionType } from '../enums/permission-type.enum';

@Injectable({
    providedIn: 'root',
})
export class PermissionTypeService {
    constructor(
        private readonly _appStoreService: AppStoreService
    ) {}

    userHasPermissionType(permissionType: PermissionType | null): boolean {
        if(permissionType != null){
            const userPermissionTypes = this.getUserPermissionTypes();
            if(userPermissionTypes.length > 0){
                return userPermissionTypes.includes(permissionType);
            }
            return false;
        }
        return true;
    }

    userHasAllPermissionTypes(requiredPermissionTypes: PermissionType[] = []): boolean {
        if(requiredPermissionTypes.length > 0){
            const userPermissionTypes = this.getUserPermissionTypes();
            if(userPermissionTypes.length > 0){
                return requiredPermissionTypes.every(rpt => userPermissionTypes.includes(rpt));
            }
            return false;
        }
        return true;
    }

    private getUserPermissionTypes(): PermissionType[]{
        const token: Token | null = this._appStoreService.token;
        if (token && token.privileges && token.privileges.length > 0) {
            const userPermissionTypes: PermissionType[] = token.privileges.map((userPermissionType) => {
                return Object.values(PermissionType)[Object.keys(PermissionType).indexOf(userPermissionType)];
            });

            if(userPermissionTypes.length > 0){
                return userPermissionTypes;
            }
        }
        return [];
    }
}
